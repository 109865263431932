<template>
  <custom-table
    ref="customTable"
    :columns="columns"
    :custom-view="customView"
    api-url="/goods-return-history"
    api-download-url="/goods-return-history-export"
    download-file-name="hang-hoan.xlsx"
  ></custom-table>
</template>

<script>
import {
  DELIVERY_STATE,
  SEAL_STATUS_OPTIONS,
  CUSTOM_VIEW_WAREHOUSE_RETURN,
} from "@/libs/const";

export default {
  name: "History",
  data: () => ({
    columns: [],
    customView: CUSTOM_VIEW_WAREHOUSE_RETURN,
    statusOptions: [...SEAL_STATUS_OPTIONS],
    deliveryStateOption: [...DELIVERY_STATE],
  }),
  created() {
    const columns = [
      {
        type: "select-warehouse",
        label: this.$t("labels.warehouse"),
        placeholder: this.$t("labels.warehouse"),
        name: "id_warehouse",
        hasSort: true,
        sortName: "warehouse_code",
        key: "warehouse_code",
        required: true,
      },
      {
        type: "input-filter",
        label: this.$t("labels.tracking"),
        placeholder: this.$t("labels.tracking"),
        name: "tracking_id",
        hasSort: true,
        sortName: "tracking_id",
        key: "tracking_id",
        required: true,
        component: "OrderTracking",
      },
      {
        type: "select-e-market",
        label: this.$t("labels.e_market"),
        placeholder: this.$t("labels.e_market"),
        class: "c-input-xs input-filter",
        name: "id_e_market",
        hasSort: true,
        sortName: "e_market_name",
        key: "e_market_name",
      },
      {
        type: "input-filter",
        label: this.$t("labels.customer_order_id"),
        placeholder: this.$t("labels.customer_order_id"),
        name: "customer_order_id",
        hasSort: true,
        sortName: "customer_order_id",
        key: "customer_order_id",
        required: true,
      },
      {
        type: "input-filter",
        label: this.$t("labels.receiver"),
        placeholder: this.$t("labels.receiver"),
        name: "receiver_name",
        hasSort: true,
        sortName: "receiver_name",
        key: "receiver_name",
      },
      {
        type: "input-filter",
        label: this.$t("labels.phone_number"),
        placeholder: this.$t("labels.phone_number"),
        name: "receiver_phone_number",
        hasSort: true,
        sortName: "receiver_phone_number",
        key: "receiver_phone_number",
      },
      {
        type: "input-filter",
        label: this.$t("labels.city"),
        placeholder: this.$t("labels.city"),
        name: "city_name",
        hasSort: true,
        sortName: "city_name",
        key: "city_name",
      },
      {
        type: "input-filter",
        label: this.$t("labels.district"),
        placeholder: this.$t("labels.district"),
        name: "county_name",
        hasSort: true,
        sortName: "county_name",
        key: "county_name",
      },
      {
        type: "input-filter",
        label: this.$t("labels.address"),
        placeholder: this.$t("labels.address"),
        name: "address",
        hasSort: true,
        sortName: "address",
        key: "address",
      },
      {
        type: "input-filter",
        label: this.$t("labels.cod"),
        placeholder: this.$t("labels.cod"),
        name: "cash_on_delivery",
        hasSort: true,
        sortName: "cash_on_delivery",
        key: "cash_on_delivery",
      },
      {
        type: "input-filter",
        label: this.$t("labels.barcode"),
        placeholder: this.$t("labels.barcode"),
        name: "customer_goods_barcode",
        hasSort: true,
        sortName: "customer_goods_barcode",
        key: "customer_goods_barcode",
        required: true,
        component: "GoodsCode",
      },
      {
        type: "select-filter",
        label: this.$t("labels.seal_status"),
        placeholder: this.$t("labels.seal_status"),
        name: "seal_status",
        hasSort: false,
        sortName: "seal_status",
        key: "seal_status",
        options: this.statusOptions,
        required: true,
      },
      {
        type: "input-filter-from-to",
        label: this.$t("labels.quantity_1"),
        placeholder: this.$t("labels.quantity_1"),
        name: "order_quantity",
        hasSort: false,
        sortName: "order_quantity",
        key: "order_quantity",
        options: this.statusOptions,
        required: true,
      },
      {
        type: "input-filter-from-to",
        label: this.$t("labels.return_import"),
        placeholder: this.$t("labels.return_import"),
        name: "quantity",
        hasSort: false,
        sortName: "quantity",
        key: "quantity",
        options: this.statusOptions,
        required: true,
      },
      {
        type: "input-filter-from-to",
        label: this.$t("labels.sub_quantity"),
        placeholder: this.$t("labels.sub_quantity"),
        name: "sub_quantity",
        hasSort: false,
        sortName: "sub_quantity",
        key: "sub_quantity",
        options: this.statusOptions,
        required: true,
      },
      {
        type: "input-filter",
        label: this.$t("labels.delivery_order_id"),
        placeholder: this.$t("labels.delivery_order_id"),
        name: "delivery_order_id",
        hasSort: true,
        sortName: "delivery_order_id",
        key: "delivery_order_id",
        required: true,
        component: "DeliveryTracking",
      },
      {
        type: "input-filter",
        label: this.$t("labels.delivery_status"),
        placeholder: this.$t("labels.delivery_status"),
        name: "delivery_partner_state",
        hasSort: true,
        sortName: "delivery_partner_state",
        key: "delivery_partner_state",
        required: true,
      },
      {
        type: "select-filter",
        label: this.$t("labels.summary_status"),
        placeholder: this.$t("labels.summary_status"),
        name: "id_delivery_state",
        hasSort: true,
        sortName: "id_delivery_state",
        key: "id_delivery_state",
        options: this.deliveryStateOption,
        required: true,
      },
      {
        type: "date-range-filter",
        label: this.$t("labels.return_receipt"),
        placeholder: this.$t("labels.return_receipt"),
        name: "confirm_return_at",
        hasSort: true,
        sortName: "confirm_return_at",
        key: "confirm_return_at",
      },
      {
        type: "date-range-filter",
        label: this.$t("labels.return_import"),
        placeholder: this.$t("labels.return_import"),
        name: "receipt_return_at",
        hasSort: true,
        sortName: "receipt_return_at",
        key: "receipt_return_at",
      },
      {
        type: "input-filter",
        label: this.$t("labels.note"),
        placeholder: this.$t("labels.note"),
        name: "note",
        hasSort: true,
        sortName: "note",
        key: "note",
        required: true,
      },
    ];
    this.columns = [...columns];
  },
};
</script>

<style scoped></style>
